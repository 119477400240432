.App {
  text-align: center;
}

.bottom-gradient-text {
  position: relative;
  display: inline-block;

  /* Apply gradient background */
  background: linear-gradient(to bottom, #D17C7800 0%, #D17C7883 40%, #D17C78 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: "orbit 5s linear infinite";
}



.upper-gradient-text {
  position: relative;
  display: inline-block;

  /* Apply gradient background */
  background: linear-gradient(to bottom, #D17C78, #D17C7883);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@font-face {
  font-family: 'VisionRegular';
  src: url('./fonts/CinzelDecorative-Regular.ttf') format('truetype');
}

/* write a font face to export the font from the file system */
@font-face {
  font-family: 'VisionBold';
  src: url('./fonts/CinzelDecorative-Bold.ttf') format('truetype');
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
